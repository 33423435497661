let heroHeader = document.getElementById('heroHeader');
let navContent = document.getElementById('navContent');
let pageContent = document.getElementById('pageContent');
let pricingCardDropdown = document.getElementsByClassName('vsp-c-pricing-card__other-wrapper');
let pricingPlansDropdown = document.getElementsByClassName('vsp-c-landing-pricing__plans-item-list--second');
let pricingTabs = document.getElementsByClassName('vsp-c-pricing-nav__tab');
let pricingTabsContent = document.getElementsByClassName('vsp-c-pricing-content-wrapper');
let getAQuoteElements = document.querySelectorAll('[id=getAQuote]');
let getAQuoteModal = document.getElementById('getAQuoteModal');
let messageSentModal = document.getElementById('messageSentModal');
let getAQuoteEmail = document.getElementById('getAQuoteEmail');
let getAQuoteEmailBody = document.getElementById('getAQuoteEmailBody');
let getAQuoteForm = document.getElementById('getAQuoteForm');
let pricingTabsNavbar = document.querySelector('.vsp-c-pricing-nav');
let numberOfUsers = document.getElementById('numberOfUsers');
let scheduleDemoForm = document.getElementById('scheduleDemoForm');
let scheduleDemoModal = document.getElementById('scheduleDemoModal');

let eventTypeButton =null;

const monthlyPlans = document.querySelector('.vsp-c-pricing-plan-list--monthly');
const annualPlans = document.querySelector('.vsp-c-pricing-plan-list--annual');
const monthlyPlansOptionDiv = document.querySelectorAll('.vsp-c-switch-pricing-plan-option')[0];
const annualPlansOptionDiv = document.querySelectorAll('.vsp-c-switch-pricing-plan-option')[1];

const isScrolledClass = 'vsp-c-is-scrolled';
const scrollableHeroClass = 'vsp-c-content-gap--scrollable-hero';

const pricingCardDropdownActive = 'vsp-c-pricing-card__other--dropdown-active';
const pricingPlansDropdownActive = 'vsp-c-landing-pricing__plans--dropdown-active';

const displayNone = 'vsp-u-display--none';
const activeTab = 'vsp-is-active';
const overflowHidden = 'vsp-u-overflow--hidden';

const subject = 'Get A Quote'

const scheduleDemo = 'Schedule a Demo'

const scheduleDemoJira = 'Jira alternative page';
const scheduleDemoHome = 'Try Demo button';

const enterprise = 'Enterprise'
const signup = 'Signup Activities';
const pricingPage = 'Pricing page';

function sendGaEvent(eventCategory, eventAction, eventLabel) {
  if (window.ga) {
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel
    });
  }
}

const scheduleDemoSubject = 'Schedule a Demo';

function watchHeader() {
  window.pageYOffset >= 1
    ? addHeaderAndContentClasses()
    : removeHeaderAndContentClasses();
}

function addHeaderAndContentClasses() {
  pageContent.classList.add(isScrolledClass);
  heroHeader.classList.add(isScrolledClass);
}

function removeHeaderAndContentClasses() {
  pageContent.classList.remove(isScrolledClass);
  heroHeader.classList.remove(isScrolledClass);
}

function watchInsightsNavbar() {
  window.pageYOffset >= heroHeader.offsetHeight
    ? addInsightsNavAndContentClasses()
    : removeInsightsNavAndContentClasses();
}

function addInsightsNavAndContentClasses() {
  navContent.classList.add(isScrolledClass);
  pageContent.classList.add(scrollableHeroClass);
}

function removeInsightsNavAndContentClasses() {
  navContent.classList.remove(isScrolledClass);
  pageContent.classList.remove(scrollableHeroClass);
}

function closeModal(modal) {
  modal.classList.add(displayNone);
  document.body.classList.remove(overflowHidden);
}

(function () {
  if (getAQuoteElements && getAQuoteElements.length > 0) {
    getAQuoteElements.forEach(getAQuoteElement => {
      getAQuoteElement.addEventListener('click', function (e) {
        sendGaEvent(signup, 'Click get a quote', pricingPage);
        getAQuoteModal.classList.remove(displayNone);
        document.body.classList.add(overflowHidden);
      });
      document.querySelector('#messageSentModal .vsp-c-modal__close').addEventListener('click', function (e) {
        closeModal(messageSentModal);
      });
      document.querySelector('#getAQuoteModal .vsp-c-modal__close').addEventListener('click', function (e) {
        getAQuoteForm.reset();
        closeModal(getAQuoteModal);
      });
    });
  }
})();

(function () {
  if (!pricingTabsNavbar) {
    return;
  }

  let currentActivePlan = 0;
  pricingTabsNavbar.addEventListener('click', function($event) {
    if ($event.target.dataset.position) {
      const active = Number($event.target.dataset.position);
      pricingTabsContent[currentActivePlan].classList.add(displayNone);
      pricingTabs[currentActivePlan].classList.remove(activeTab);

      pricingTabsContent[active].classList.remove(displayNone);
      pricingTabs[active].classList.add(activeTab);
      currentActivePlan = active;
    }
  });
})();

(function () {
  for (let i = 0; i < pricingCardDropdown.length; i++) {
    pricingCardDropdown[i].addEventListener('click', function (e) {
      if (pricingCardDropdown[i].classList.contains(pricingCardDropdownActive)) {
        pricingCardDropdown[i].classList.remove(pricingCardDropdownActive);
        pricingPlansDropdown[i].classList.remove(pricingPlansDropdownActive);
        return;
      }
      pricingCardDropdown[i].classList.add(pricingCardDropdownActive);
      pricingPlansDropdown[i].classList.add(pricingPlansDropdownActive);
    });
  }
})();

(function () {
  var searchEl = document.getElementById('search');
  var searchBody = document.getElementById('search-results');

  if (searchEl) {
    window.addEventListener('click', function (event) {
      if (searchBody.contains(event.target)) {
        return;
      }

      searchBody.innerHTML = '';
    });

    searchEl.addEventListener('keydown', function (event) {
      var code = (event.keyCode ? event.keyCode : event.which);
      var isEnter = code === 13;
      if (isEnter) {
        event.preventDefault();
        var termQuery = event.target.value;
        var type = searchBody.dataset.type;

        if (termQuery.length === 0) {
          return false;
        }
        searchOnEnter(termQuery, type);
      }
    });

    searchEl.addEventListener('input', function (event) {
      var termQuery = event.target.value;
      var code = (event.keyCode ? event.keyCode : event.which);

      if (termQuery.length === 0) {
        searchBody.innerHTML = '';
        return false;
      }

      if (termQuery.length < 3) {
        return false;
      }

      var type = searchBody.dataset.type;
      search(termQuery, type).then(({ posts }) => {
        if (posts.length) {
          populateSearch(posts, type, type === 'feature' ? 'how-it-works' : 'insights');
          return;
        }
        populateError();
      });
    });
  }

  function populateError() {
    searchBody.innerHTML = '<ul class="vsp-c-search__results"><li>No results found. Try a different search term.</li><br/></ul>';
  }

  function formatUrl(item, type, route) {
    if (type == 'feature') {
      return '/' + route + '/' + item.feature_group_id + '/' + item.slug;
    }

    return '/' + route + '/' + item.slug;
  }

  function populateSearch(data, type, route) {
    var list = '<ul class="vsp-c-search__results">';
    searchBody.innerHTML = '';
    for (item of data) {
      list += '<li><a href="' + formatUrl(item, type, route) + '">' + item.title + '</a></li><br/>';
    }
    list += '</ul>'
    searchBody.innerHTML = list;
  }

  function search(term, type) {
    return fetch('/search-posts?term=' + term + '&type=' + type, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
      .then(function (res) {
        const isError = (res.status !== 200);
        if (isError) {
          return Promise.reject();
        }
        return res.json();
      });
  }

  function searchOnEnter(term, type) {
    location.href = window.location.origin + '/how-it-works/search-results?term=' + term + '&type=' + type;
  }
})();

(function() {

  setupScheduleDemoModalEvents('scheduleDemoButton');
  setupScheduleDemoModalEvents('tryDemoHomeButton');
  setupScheduleDemoModalEvents('tryDemoHeaderButton');
  
  function setupScheduleDemoModalEvents(elementId) {
    let scheduleDemoButton = document.getElementById(elementId);

    if (scheduleDemoButton) {
      scheduleDemoButton.addEventListener('click', function (e) {
        scheduleDemoModal.classList.remove(displayNone);
        eventTypeButton = elementId;
      });
      document.querySelector('#messageSentModal .vsp-c-modal__close').addEventListener('click', function (e) {
        closeModal(messageSentModal);
      });
      document.querySelector('#scheduleDemoModal .vsp-c-modal__close').addEventListener('click', function (e) {
        scheduleDemoForm.reset();
        scheduleDemoModal.classList.add(displayNone);
      });
    }
  }
})();

(function() {
  if (!scheduleDemoForm) {
    return;
  }

  scheduleDemoForm.onsubmit = function (e) {
    const payload = {
      email: document.getElementById('scheduleDemoEmail').value,
      subject: scheduleDemoSubject,
      body: {
        body: document.getElementById('scheduleDemoContent').value,
        industry: document.getElementById('scheduleDemoIndustry').value,
        numberOfMembers: document.getElementById('scheduleDemoMembers').value,
        name: document.getElementById('scheduleDemoName').value
      }
    };
   
    let label = eventTypeButton === 'scheduleDemoButton' 
      ? scheduleDemoJira
      : eventTypeButton === 'tryDemoHomeButton' 
        ? scheduleDemoHome
        : null;
    if(label != null){
      sendGaEvent(scheduleDemo, 'Schedule a demo', label)
    }
    sendEmail(e, this, payload, '/api/v2/schedule-demo-email', scheduleDemoModal)
  };
})();

(function() {
  if (!getAQuoteForm) {
    return;
  }

  getAQuoteForm.onsubmit = function (e) {
    const payload = {
      email: getAQuoteEmail.value,
      subject: subject,
      body: getAQuoteEmailBody.value
    };
    sendEmail(e, this, payload, '/api/v2/get-quote-email', getAQuoteModal, true)
  };
})();

function showMessage(text, classes) {
  const messageDom = document.createElement('p');
  const messageText = document.createTextNode(text);
  messageDom.appendChild(messageText);
  messageDom.className = classes;
  let scheduleDemoButton = document.getElementById('scheduleDemoButton');

  if (scheduleDemoButton) {
    document.querySelector('#scheduleDemoModal .vsp-c-modal__section').appendChild(messageDom);
    return;
  }

  document.querySelector('#getAQuoteModal .vsp-c-modal__section').appendChild(messageDom);
};

function removeMessage(){
  const elem = document.querySelector('.vsp-c-alert');
  elem.parentNode.removeChild(elem);
};

function sendEmail(e, modalForm, payload, url, modal, isGetAQuote = false) {
  e.preventDefault();
  let form = modalForm;
  
  fetch(url, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })
  .then((res) => {
    if (res.status !== 200) {
        return res.json();
    }
    
    if (isGetAQuote) {
      sendGaEvent(enterprise, 'Get a quote from pricing', '');
    }

    form.reset();

    messageSentModal.classList.remove(displayNone);

    modal.classList.add(displayNone);
  })
  .then((err) => {
    if (err) {
      let messageTimeout = 4000;
      showMessage(err.email, 'vsp-c-alert vsp-c-alert--danger');
      return setTimeout(function() { removeMessage(); }, messageTimeout);
    }
  });
};

(function setDefaultValue() {
  if (!numberOfUsers) {
    return;
  }

  numberOfUsers.value = 10;
  numberOfUsers.dispatchEvent(new Event('input'));
})();

function calculatePrice() {
  const numberOfMonths = 12;
  const amountAfterDiscount = 0.8;
  numberOfUsers.value = Math.trunc(numberOfUsers.value);

  if (!numberOfUsers.value) {
    return;
  }

  numberOfUsers.value = numberOfUsers.value > 1000 ? 1000 : numberOfUsers.value < 1 ? 1 : numberOfUsers.value;

  let plan = plans.find((plan) => {
    return numberOfUsers.value > plan.min_members && numberOfUsers.value <= plan.max_members;
  });
  let pricePerMonth = plan.isFixed ? plan.price : plan.price * numberOfUsers.value;
  let pricePerYear = Math.round(pricePerMonth * numberOfMonths * amountAfterDiscount);
  document.getElementById("pricePerMonth").innerText = pricePerMonth;
  document.getElementById("pricePerYear").innerText = pricePerYear;
};

function showOneAndHideAnother(toShow, toHide) {
  toHide.classList.remove("is-active");
  toShow.classList.add("is-active");
}

function updateCheckedState(toCheck, toUncheck) {
  toUncheck.classList.remove("checked");
  toCheck.classList.add("checked");
}

function switchPlans(option) {
  if (option === monthlyPlansOptionDiv) {
      showOneAndHideAnother(monthlyPlans, annualPlans);
      updateCheckedState(monthlyPlansOptionDiv, annualPlansOptionDiv);
  } else {
      showOneAndHideAnother(annualPlans, monthlyPlans);
      updateCheckedState(annualPlansOptionDiv, monthlyPlansOptionDiv);
  }
}
